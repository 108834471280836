import request from '../../utils/request'

interface Combo<T> {
  packageName?: T;
  packageFit?: T;
  size: number;
  current: number;
}
//获取套餐数据
export function getComboData(data: Combo<string>): Promise<any> {
  return request({
    method: 'GET',
    url: 'manage/checkPackage/list',
    type: 'params',
    data
  })
}
//根据套餐节点id进行套餐内项目查询
export function getComboItem(id: number): Promise<any> {
  return request({
    url: 'manage/checkPackage/getPackageItem/' + id,
    method: 'get',
  })
}
//根据套餐节点id进行删除操作
export function deleteCombo(ids: number[]): Promise<any> {
  return request({
    url: 'manage/checkPackage/removeByIds?ids=' + ids.join(','),
    method: 'POST',
    type: 'params'
  })
}
//根据套餐节点id进行疾病关联操作
export function updateCombo(data: any): Promise<any> {
  return request({
    url: 'manage/checkPackage/update',
    method: 'POST',
    data: data
  })
}
